<template>
  <div class="page-info-content">
    <el-tabs v-model="tabsName" type="border-card" class="my-pane" @tab-click="handleClick">
      <el-tab-pane label="上下游企业" name="down">
        <enterpriseTab :enterprise-type="2" :cmp-name="cmpName" :relation-cmp-list="relationCmpList" @queryList="handleClick" />
      </el-tab-pane>
      <el-tab-pane label="所属核心企业" name="up">
        <enterpriseTab :enterprise-type="1" :cmp-name="cmpName" :relation-cmp-list="relationCmpList" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import EnterpriseTab from './children/enterpriseTab.vue'
import { relationCmpList } from '@/api/memberManagement.js'
export default {
  components: { EnterpriseTab },
  data() {
    return {
      tabsName: 'down',
      cmpName: this.$route.query.cmpName,
      relationCmpList: []
    }
  },
  created() {
    this.handleClick()
  },
  methods: {

    handleClick() {
      const params = {
        cmpUnicode: this.$route.query.coreCmpUnicode,
        type: this.tabsName
      }
      this.queryList(params)
    },
    queryList(params) {
      params = {
        pageNum: 1,
        pageSize: 10,
        ...params
      }
      relationCmpList(params, (res) => {
        if (res.code === 200) {
          this.relationCmpList = res.data.list
        }
      }, () => {
      })
    }
  }
}
</script>
<style lang="scss">
     .page-info-content .el-tabs__header {
        padding: 0;
      }
      .el-tabs__item {
        height: 51px;
        line-height: 51px;
      }
    .template-container .template-content {
        margin-top: 0;
    }
</style>
