<template>
  <div>
    <form-search
      :reset="false"
      :add-row="enterpriseType == 2 && addRow"
      :form-item-arr="searchFormItemArr"
      :getdata="search"
    />
    <my-table
      :item-data="coreItemData"
      :list-data="relationCmpList"
      :operation-button="operationButton"
    >
      <template #approveTime="{ row }">
        <div>{{ row.approveTime }}</div>
      </template>
    </my-table>
    <el-dialog
      title="添加上下游企业"
      :visible.sync="dialogVisible"
      width="520px"
      :close-on-click-modal="false"
      class="br-dialog add-cmp-dialog"
    >
      <el-form ref="Forms" :model="dialogFrom" label-width="110px" :inline="false" @submit.native.prevent>
        <el-form-item label="供应商名称" prop="cmpName">
          <el-autocomplete
            v-model="dialogFrom.cmpName"
            clearable
            class="inline-input"
            :fetch-suggestions="queryCmpNameSearch"
            placeholder="请输入供应商名称"
            :trigger-on-focus="false"
            @select="cmpNameSelect"
          />
        </el-form-item>
        <el-form-item v-if="dialogFrom.cmpUnicode" label="社会信用代码" prop="cmpUnicode">
          {{ dialogFrom.cmpUnicode }}
        </el-form-item>
      </el-form>

      <div slot="footer" class="br-dialog-footer">
        <el-button type="primary" class="br-btn-large br-submit-btn" @click="handleSubmit">
          提交
        </el-button>
        <el-button class="br-btn-large br-cancel-btn" @click="dialogVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectNoCmpRelation, addFinanceCmpRelation, deleteCmpRelation } from '@/api/memberManagement.js'
import FormSearch from '../../../components/FormSearch.vue'
import MyTable from '../../../components/Table.vue'
export default {
  components: { FormSearch, MyTable },
  props: {
    relationCmpList: {
      type: Array,
      default: () => ([])
    },
    enterpriseType: {
      type: Number,
      default: () => (2)
    }
  },
  data() {
    return {
      dialogFrom: {
        coreCmpUnicode: this.$route.query.coreCmpUnicode
      },
      dialogVisible: false,
      searchData: {
        searchName: ''
      },
      searchFormItemArr: [
        {
          type: 'input',
          label: '企业名称',
          value: 'searchName'
        }
      ],
      coreItemData: [
        { label: '企业名称', prop: 'cmpName', width: 180 },
        { label: '企业统一社会信用代码证号', prop: 'cmpUnicode', width: 180 },
        { label: '关联关系建立时间', prop: 'approveTime', width: 180 }
      ],
      operationButton: [
        { bType: 'danger', label: '删除', handleEvent: this.deleteCore }
      ]
    }
  },
  methods: {
    search() {
      this.$emit('queryList', { cmpName: this.searchName })
    },
    addRow() {
      this.dialogVisible = true
    },
    deleteCore(row) {
      this.$confirm('是否确定删除关联供应商？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCmpRelation(row.id, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.$emit('queryList')
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        })
    },
    // 企业名称查询后选择回调
    cmpNameSelect(item) {
      // cmpUnicode
      this.dialogFrom.cmpUnicode = item.cmpUnicode
    },
    handleSubmit() {
      if (!this.dialogFrom['cmpUnicode']) {
        return this.$message({
          type: 'error',
          message: '未找到该供应商，请重新输入选择'
        })
      }
      addFinanceCmpRelation(this.dialogFrom, res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.$emit('queryList')
          this.dialogVisible = false
        }
      })
    },
    queryCmpNameSearch(queryString, cb, t) {
      const param = {
        cmpName: queryString,
        coreCmpUnicode: this.$route.query.coreCmpUnicode
      }
      selectNoCmpRelation(param, res => {
        if (res.code === 200) {
          const results = JSON.parse(JSON.stringify(res.data).replace(/cmpName/g, 'value'))
          this.cmpNameList = results
          if (results[0]) {
            // this.dialogFrom.cmpUnicode = results[0].cmpUnicode;
            this.dialogFrom.cmpName = queryString
            this.$forceUpdate()
          } else {
            this.dialogFrom.cmpUnicode = null
            // this.$message({
            //     type: 'info',
            //     message: '未查到' + queryString + '，请重新输入'
            // });
          }
          if (cb) cb(results)
        } else {
          this.$message({
            type: 'info',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
